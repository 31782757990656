import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Modal} from 'antd';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

const EmojiCreateModal = ({modalOpen, setModalOpen, handleAddNewEmoji}) => {
  const [selectedEmoji, setSelectedEmoji] = useState(undefined);

  const handleSave = () => {
    handleAddNewEmoji(selectedEmoji.native)
    closeModal();
  }

  const closeModal = () => {
    setSelectedEmoji(undefined)
    setModalOpen(false);
  }

  return <Modal
    width={400}
    open={modalOpen}
    title="신규 이모티콘 추가하기"
    onCancel={closeModal}
    footer={[
      <Button key="back" onClick={closeModal}>
        취소
      </Button>,
      <Button key="ok" onClick={handleSave} disabled={selectedEmoji === undefined}>
        저장
      </Button>,
    ]}
  >
    <div>
      <Picker data={data}
              onEmojiSelect={setSelectedEmoji}
              maxFrequentRows={0}
              searchPosition={'none'}
              navPosition={'none'}
              previewPosition={'none'}
              theme={'light'}
      />
      <p style={{marginTop: '10px'}}><b>선택된 이모티콘:</b> {selectedEmoji ? selectedEmoji.native : '-'}</p>
    </div>
  </Modal>;
};

export default observer(EmojiCreateModal);
