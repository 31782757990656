import axios from 'axios';
import * as cookie from 'browser-cookies';
import axiosConfig from '../shared/AxiosConfig'

export const authentication = {
  signUp,
  login,
  logout,

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  },
  get user() {
    const userData = localStorage?.getItem('user') || '{}';
    return JSON.parse(userData);
  },
  get token() {
    return cookie.get('token')
  }
};

async function signUp(email, name, password) {
  const axiosInstance = axios.create();
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

  try {
    await axiosInstance.post('/v1/admin-users/sign-up', {
      email,
      name,
      password
    })
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function login(id, password) {
  const axiosInstance = axios.create();
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

  try {
    const {data} = await axiosInstance.post('/v1/admin-users/sign-in', {
      email: id,
      password: password
    }, null);
    authentication.setUser({
      id: 'admin',
      name: data?.name,
    })
    cookie.set('token', data?.accessToken, {
      expires: new Date(new Date().setHours(new Date().getHours() + 2)).toString(),
    })
    axiosConfig.setDefaultAxiosSetting();
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

function logout () {
  localStorage.removeItem('user');
  cookie.erase('token');
  authentication.setUser({});
}
