import {action, computed, observable, runInAction} from 'mobx';
import {CONTENTS_TYPE, STORE_STATE} from "../../constants/Values";
import {
  getComplaints,
  getPost,
  getComment,
  blockingPost,
  completeComplaint,
  blockingComment,
  unblockingComment
} from "../../shared/CamlyApi";

const ComplaintStore = () => observable.object({
  state: STORE_STATE.DEFAULT,
  modalState: STORE_STATE.DEFAULT,
  complaints: {},
  commentComplaints: {},

  get isLoading () {
    return this.state === STORE_STATE.LOADING;
  },

  async fetchComplainedContents (contentId, type) {
    this.modalState = STORE_STATE.LOADING;
    let data = {};
    switch (type) {
      case CONTENTS_TYPE.POST:
        const {data: post} = await getPost(contentId);
        data = post;
        break;
      case CONTENTS_TYPE.COMMENT:
        const {data: comment} = await getComment(contentId)
        data = comment;
        break;
      default:
        this.modalState = STORE_STATE.ERROR;
        break;
    }
    this.modalState = STORE_STATE.DONE;
    return data;
  },

  async fetchComplaint (complainType, pagination) {
    this.state = STORE_STATE.LOADING;
    const {data} = await getComplaints(complainType, pagination);
    const complaintsData = data.datas;
    for (const d of complaintsData) {
      if (CONTENTS_TYPE.POST === complainType) {
        const {data: post} = await getPost(d.targetId);
        d.post = post;
      }
      if (CONTENTS_TYPE.COMMENT === complainType) {
        const {data: comment} = await getComment(d.targetId);
        d.comment = comment;
      }
    }
    runInAction(() => {
      this.complaints.data = complaintsData;
      this.complaints.totalCount = data.paging?.totalCount;
    })
    this.state = STORE_STATE.DONE;
  },

  async blockingPostByComplaint (postId) {
    await blockingPost(postId);
  },

  async complete (id) {
    await completeComplaint(id);
  },

  async blockingCommentByComplaint (commentId) {
    await blockingComment(commentId);
    await this.fetchCommentComplaint();
  },

  async unblockingCommentByComplaint (commentId) {
    await unblockingComment(commentId);
    await this.fetchCommentComplaint();
  }

}, {
  isLoading: computed,
  fetchComplaint: action.bound,
  blockingPostByComplaint: action.bound,
  complete: action.bound,
  blockingCommentByComplaint: action.bound,
  unblockingCommentByComplaint: action.bound,
});

export default ComplaintStore;
