import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Button, Col, Form, Input, Modal, Row, Table, Radio, message, Popconfirm, Pagination} from "antd";
import {ACCOUNT_BLOCK_TYPE, STORE_STATE} from "../../../constants/Values";
import BlockedAccountStore from "./AccountBlockStore";
import moment from "moment/moment";

const columns = (unblockAccount) => [
  {
    title: '계정',
    dataIndex: 'accountId',
    key: 'accountId',
  },
  {
    title: '차단형태',
    dataIndex: 'type',
    key: 'type',
    render: type => ACCOUNT_BLOCK_TYPE[type]?.desc,
  },
  {
    title: '차단 시작일',
    dataIndex: 'startDate',
    key: 'startDate',
    render: date => moment(date).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: '차단 해제일',
    dataIndex: 'endDate',
    key: 'endDate',
    render: date => moment(date).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: '비고',
    dataIndex: 'comment',
    key: 'comment',
  },
  {
    title: '즉시해제',
    render: data => <Popconfirm
      title="이용자 제재 즉시해제"
      description={<>해당 이용자에 대한 제재가 현재시간 기준으로 해제됩니다.<br/>진행 하시겠습니까?</>}
      onConfirm={() => unblockAccount(data.accountId)}
      okText="Yes"
      cancelText="No"
    >
      <Button type='default' danger>해제</Button>
    </Popconfirm>
  },
]

const AccountBlock = () => {
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [form] = Form.useForm();
  const [accountBlockStore] = useState(BlockedAccountStore());
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    accountBlockStore.fetchBlockedAccounts(pagination);
  }, [pagination])

  const showModal = () => {
    form.resetFields();
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const blockAccount = async values => {
    setModalLoading(true);
    const result = await accountBlockStore.block(values);
    setModalLoading(false);
    if (result) {
      closeModal();
      accountBlockStore.fetchBlockedAccounts(pagination);
      message.info({content: '계정 제재에 성공했습니다.', key: 104, duration: 4});
    } else {
      message.error({content: '계정 제재에 실패했습니다.', key: 104, duration: 4});
    }
  }

  const unblockAccount = async accountId => {
    const result = await accountBlockStore.unblock(accountId);
    if (result) {
      accountBlockStore.fetchBlockedAccounts(pagination);
      message.info({content: '계정 제재 즉시해제에 성공했습니다.', key: 104, duration: 4});
    } else {
      message.error({content: '계정 제재 즉시해제에 실패했습니다.', key: 104, duration: 4});
    }
  }

  return (<>
    <Row>
      <Col span={24}>
        <h2>계정 관리</h2>
        <p>특정 계정에게 Camly 서비스 사용제한을 부여할 수 있는 페이지</p>
      </Col>
    </Row>
    <div style={{height: '40px', textAlign: 'right'}}>
      <Button onClick={showModal}>신규 제재</Button>
    </div>
    <Table
      rowKey={data => data.id}
      columns={columns(unblockAccount)}
      dataSource={accountBlockStore.blockedAccount.data}
      loading={accountBlockStore.isLoading}
      pagination={false}
    />
    <div style={{marginTop: 10, textAlign: 'right'}}>
      <Pagination
        showSizeChanger
        pageSizeOptions={[10,20,30]}
        defaultPageSize={pagination.size}
        total={accountBlockStore.blockedAccount?.totalCount}
        current={pagination.page + 1}
        onChange={(page, size) => {setPagination({page: page - 1, size})}}
      />
    </div>
    <Modal
      open={modalOpen}
      title="계정 제재하기"
      onOk={closeModal}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          취소
        </Button>,
        <Button key="submit" type="primary" loading={modalLoading} onClick={form.submit}>
          등록
        </Button>,
      ]}
    >
      <Form form={form} name="register" onFinish={blockAccount}>
        <Form.Item
          name="accountId"
          label="계정ID"
          rules={[
            {
              required: true,
              message: '계정 ID는 필수값 입니다.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="blockType"
          label="차단유형"
          initialValue="WRITE_BLOCK"
        >
          <Radio.Group>
            <Radio.Button value={ACCOUNT_BLOCK_TYPE.READ_WRITE_BLOCK.name} disabled>{ACCOUNT_BLOCK_TYPE.READ_WRITE_BLOCK.desc}</Radio.Button>
            <Radio.Button value={ACCOUNT_BLOCK_TYPE.WRITE_BLOCK.name}>{ACCOUNT_BLOCK_TYPE.WRITE_BLOCK.desc}</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="blockingPeriodDays"
          label="차단기간"
          initialValue="3"
        >
          <Radio.Group>
            <Radio.Button value="3">3일</Radio.Button>
            <Radio.Button value="5">5일</Radio.Button>
            <Radio.Button value="10">10일</Radio.Button>
            <Radio.Button value="99999">무기한</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="comment"
          label="코멘트"
        >
          <Input />
        </Form.Item>

      </Form>
    </Modal>
  </>);
};

export default observer(AccountBlock);
