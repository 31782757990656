import {action, computed, observable} from 'mobx';
import {CONTENTS_TYPE, STORE_STATE} from "../../constants/Values";
import {getEmojis, createEmoji, updateEmojiUsable} from "../../shared/CamlyApi";
import _ from 'lodash';

const EmojiStore = () => observable.object({
  postState: STORE_STATE.DEFAULT,
  commentState: STORE_STATE.DEFAULT,
  postEmojis: [],
  commentEmojis: [],

  async fetchPostEmojis () {
    this.postState = STORE_STATE.LOADING;
    const {data} = await getEmojis();
    this.postEmojis = _.filter(data?.emojis, {domainType: CONTENTS_TYPE.POST}).map(e => ({...e, key: e.id}));
    this.postState = STORE_STATE.DONE;
  },

  async fetchCommentEmojis () {
    this.commentState = STORE_STATE.LOADING;
    const {data} = await getEmojis();
    this.commentEmojis = _.filter(data?.emojis, {domainType: CONTENTS_TYPE.COMMENT}).map(e => ({...e, key: e.id}));
    this.commentState = STORE_STATE.DONE;
  },

  async createNewEmoji (code) {
    this.postState = STORE_STATE.LOADING;
    await createEmoji({
      domainType: CONTENTS_TYPE.POST,
      code
    })
    this.postState = STORE_STATE.DONE;
  },

  async updateEmojiUsable (ids, usable) {
    this.postState = STORE_STATE.LOADING;
    await updateEmojiUsable({
      ids,
      usable
    })
    this.postState = STORE_STATE.DONE;
  }

}, {
  fetchPostEmojis: action.bound,
  fetchCommentEmojis: action.bound,
  createNewEmoji: action.bound,
  updateEmojiUsable: action.bound,
});

export default EmojiStore;
