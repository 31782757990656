import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Button, Card, Col, Form, Input, message, Row } from "antd";
import NotificationStore from "./NotificationStore";
import { STORE_STATE } from "../../constants/Values";

const Notification = () => {

  const [notificationStore] = useState(NotificationStore());
  const [title, setTitle] = useState(undefined);
  const [body, setBody] = useState(undefined);
  const [landingUrl, setLandingUrl] = useState(undefined);
  const [testAccountIds, setTestAccountIds] = useState([]);


  useEffect(() => {

  }, []);

  const onFinish = async (values) => {
    try {
      const result = await notificationStore.sendNotifications(values);
      message.success({ content: '전송 완료. 성공 : ' + result.data.successCount + '. 실패 : ' + result.data.failureCount, key: 100, duration: 4 });
    } catch (error) {
      message.error({ content: '전송 실패', key: 100, duration: 4 });
    }
  };


  return (<>
    <Row>
      <Col span={24}>
        <h2>알림 발송</h2>
        <p>알림 발송을 위한 페이지 (즉시발송)</p>
      </Col>
    </Row>

    <Card title={'알림 발송'} loading={notificationStore.state === STORE_STATE.LOADING}>
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={{ title: "", body: "" }}
        onFinish={onFinish}
      >
        <Form.Item
          label="제목"
          name="title"
          rules={[{ required: true, message: '제목은 필수사항 입니다.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="내용"
          name="body"
          rules={[{ required: true, message: '내용은 필수사항 입니다.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="landingUrl"
          name="landingUrl"
          rules={[{ type: 'url', message: '유효한 URL을 입력하세요.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Test account ids"
          name="testAccountIds"
          rules={[{ message: 'account id 를 잘 못 입력함' }]}
        >
          <Input placeholder="쉼표로 구분된 계정 ID 목록, 없으면 전체" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 10, span: 12 }} shouldUpdate>
          <Button onClick={() => navigate('/notification')} style={{ marginRight: '5px' }}>
            취소
          </Button>
          <Button type="primary" htmlType="submit" style={{ marginLeft: '5px' }}>
            전송(즉시 발송)
          </Button>
        </Form.Item>
      </Form>
    </Card>

  </>);
};

export default observer(Notification);
