import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import NoticeStore from "./NoticeStore";
import {Button, Card, Col, DatePicker, Form, Input, message, Modal, Pagination, Popconfirm, Row, Table} from "antd";
import moment from "moment";
import _ from "lodash";
import {useNavigate} from "react-router-dom";

const columns = (handleDeleteConfirm, showNoticeModal, showPromotionModal, navigate) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '생성일',
    dataIndex: 'createdDate',
    key: 'created',
    render: createdDate => moment(createdDate).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: '수정일',
    dataIndex: 'modifiedDate',
    key: 'updated',
    render: modifiedDate => moment(modifiedDate).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: '제목',
    key: 'title',
    render: data => <Button type='link' onClick={() => showNoticeModal(data.id)}>{data.postContent.title}</Button>,
  },
  {
    title: '계정',
    dataIndex: 'accountName',
    key: 'name',
  },
  {
    title: '처리',
    dataIndex: 'id',
    key: 'modify',
    render: noticeId => <>
      <Button onClick={() => showPromotionModal(noticeId)}>프로모션 등록</Button>
      <Button style={{marginLeft: '5px'}} onClick={() => navigate(`/notice/upsert?noticeId=${noticeId}`)}>수정</Button>
      <Popconfirm
        title="정말 해당 공지사항을 삭제 하시겠습니까?"
        onConfirm={() => {
          handleDeleteConfirm(noticeId)
        }}
        okText="확인"
        cancelText="취소"
      >
        <Button danger style={{marginLeft: '5px'}}>삭제</Button>
      </Popconfirm>
    </>,
  },
]

const Notice = () => {
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const navigate = useNavigate();
  const [noticeStore] = useState(NoticeStore());
  const [modalOpen, setModalOpen] = useState(false);
  const [promotionModalOpen, setPromotionModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [currentNotice, setCurrentNotice] = useState();
  const noticeCategoryId = process.env.REACT_APP_NOTICE_CATEGORY

  useEffect(() => {
    noticeStore.fetchNotice(noticeCategoryId, pagination);
  }, [pagination])

  const handleDeleteConfirm = noticeId => {
    noticeStore.deleteNotice(noticeId)
      .then(() => {
        message.success({content: '공지사항 삭제 성공!', key: 100, duration: 4});
        noticeStore.fetchNotice(noticeCategoryId, pagination);
      })
      .catch(() => message.error({content: '공지사항 삭제에 실패했습니다', key: 100, duration: 4}))
  }

  const showNoticeModal = noticeId => {
    setCurrentNotice(_.find(noticeStore.notice.data, {id: noticeId})?.postContent)
    setModalOpen(true);
  }

  const showPromotionModal = noticeId => {
    form.resetFields();
    const content = _.find(noticeStore.notice.data, {id: noticeId})?.postContent;
    content.id = noticeId;
    setCurrentNotice(content)
    setPromotionModalOpen(true);
  }

  const closeModal = () => {
    setModalOpen(false);
  }

  const closePromotionModal = () => {
    setPromotionModalOpen(false);
  }

  const handleSave = async values => {
    setModalLoading(true);
    const title = values['title'];
    const rangeTimeValue = values['range-time-picker'];

    const result = await noticeStore.createPromotion({
      noticeId: currentNotice.id,
      title,
      startDate: rangeTimeValue[0].format('YYYY-MM-DDTHH:mm:ss+09:00'),
      endDate: rangeTimeValue[1].format('YYYY-MM-DDTHH:mm:ss+09:00'),
    })
    setModalLoading(false);
    if (result) {
      message.info({content: '프로모션 등록에 성공했습니다.', key: 104, duration: 4});
    } else {
      message.error({content: '프로모션 등록에 실패했습니다.', key: 104, duration: 4});
    }
    closePromotionModal();
  };

  return (<>
    <Row>
      <Col span={24}>
        <h2>공지사항 관리</h2>
        <p>공지사항 생성/조회/수정/삭제를 위한 페이지</p>
      </Col>
    </Row>
    <div style={{height: '40px', textAlign: 'right'}}>
      <Button onClick={() => navigate('/notice/upsert')}>새 공지사항 쓰기</Button>
    </div>
    <div className="card-container">
      <Table
        rowKey={data => data.id}
        columns={columns(handleDeleteConfirm, showNoticeModal, showPromotionModal, navigate)}
        dataSource={noticeStore.notice.data}
        loading={noticeStore.isLoading}
        pagination={false}
      />
    </div>
    <div style={{marginTop: 10, textAlign: 'right'}}>
      <Pagination
        showSizeChanger
        pageSizeOptions={[10,20,30]}
        defaultPageSize={pagination.size}
        total={noticeStore.notice?.totalCount}
        current={pagination.page + 1}
        onChange={(page, size) => {setPagination({page: page - 1, size})}}
      />
    </div>
    <Modal
      open={modalOpen}
      title="공지사항 상세보기"
      onOk={closeModal}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          확인
        </Button>,
      ]}
    >
      <Card title={currentNotice?.title} bordered={true}>
        <div style={{whiteSpace: 'pre-wrap'}}>{currentNotice?.content}</div>
      </Card>
    </Modal>
    <Modal
        open={promotionModalOpen}
        title="프로모션 등록하기"
        onOk={closePromotionModal}
        onCancel={closePromotionModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            취소
          </Button>,
          <Button key="ok" loading={modalLoading} onClick={form.submit}>
            저장
          </Button>,
        ]}
    >
      <div>
        <Form form={form} name="register" onFinish={handleSave}>
          <Form.Item name="title" label="프로모션 타이틀"
                     rules={[
                       {
                         required: true,
                         message: '타이틀은 필수값 입니다.',
                       },
                     ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="range-time-picker" label="게시 기간"
                     rules={[
                       {
                         type: 'array',
                         required: true,
                         message: 'Please select time!',
                       },
                     ]}
          >
            <DatePicker.RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  </>);
};

export default observer(Notice);
