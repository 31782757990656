import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {Layout, Form, Button, Input, message, Modal} from "antd";
import {authentication} from "../../auth/Authentication";

const Index = () => {
  const phase = `${process.env.REACT_APP_PHASE}`;
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const showModal = () => {
    form.resetFields();
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getNextLocation = () => {
    if (location.state) {
      return location.state.from;
    }
    return {pathname: '/'};
  };

  const onFinish = async (values) => {
    message.loading({content: '로그인중...', key: 100});
    const isConfirmed = await authentication.login(values.id, values.password);
    if (isConfirmed) {
      const from = getNextLocation();
      if (from.pathname.indexOf('login') !== -1) {
        from.pathname = '/';
      }
      message.info({content: '로그인 성공!', key: 100, duration: 4});
      navigate(from);
    } else {
      message.error({content: '로그인 실패', key: 100, duration: 4});
    }
  };

  const createAdminAccount = async (values) => {
    setModalLoading(true);
    const isConfirmed = await authentication.signUp(values.email, values.nickname, values.password);
    setModalLoading(false);
    if (isConfirmed) {
      closeModal()
      message.info({content: '계정생성 성공! 로그인을 진행해주세요.', key: 100, duration: 4});
    } else {
      message.error({content: '계정생성 실패', key: 100, duration: 4});
    }
  }

  return (
    <Layout className="layout" style={{background: '#fff'}}>
      <Layout.Content  style={{padding: '200px 50px', position: 'relative'}}>
        <div style={{textAlign: 'center'}}>
          <h1><b style={{color: '#33d121'}}>Camly</b> 어드민 운영툴 {phase === 'dev' && 'DEV'}</h1>
        </div>
        <div style={{width: '300px', margin: '35px auto', textAlign: 'center'}}>
          <Form onFinish={onFinish} className="login-form">
            <Form.Item
              style={{display: 'block'}}
              name="id"
              rules={[{ required: true, message: 'Please input your id!' }]}
            >
              <Input
                prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder="아이디"
              />
            </Form.Item>
            <Form.Item
              style={{display: 'block'}}
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password
                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                placeholder="비밀번호"
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
              <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                로그인
              </Button>
              {/*<Button onClick={showModal} style={{width: '100%', marginTop: '10px'}}>*/}
              {/*  회원가입*/}
              {/*</Button>*/}
            </Form.Item>
          </Form>
          <Modal
            open={modalOpen}
            title="Camly 어드민 회원가입"
            onOk={closeModal}
            onCancel={closeModal}
            footer={[
              <Button key="back" onClick={closeModal}>
                취소
              </Button>,
              <Button key="submit" type="primary" loading={modalLoading} onClick={form.submit}>
                등록
              </Button>,
            ]}
          >
            <Form form={form} name="register" onFinish={createAdminAccount}>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'E-mail은 필수값 입니다.',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="nickname"
                label="Nickname"
                rules={[
                  {
                    required: true,
                    message: '닉네임은 필수값 입니다.',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: '비밀번호를 입력해주세요.',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: '비밀번호가 일치하지 않습니다.',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </Layout.Content>
      <Layout.Footer style={{textAlign: 'center', position: 'fixed', bottom: 0, width: '100%'}}>
        Copyright © 605Labs Corp. All rights reserved.
      </Layout.Footer>
    </Layout>
  );
};

export default Index;
