import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Card, Col, List, Row, Switch} from "antd";
import FeatureToggleStore from "./FeatureToggleStore";

const FeatureToggle = () => {
  const [store] = useState(FeatureToggleStore());

  useEffect(() => {
    store.fetchFeatureToggle();
  }, []);

  const handleOnChange = (type, checked) => {
    store.updateFeatureToggle({
      featureType: type,
      active: checked
    });
  };

  return (<>
    <Row>
      <Col span={24}>
        <h2>피처토글 관리</h2>
        <p>피처토글 ON/OFF 관리를 위한 페이지</p>
      </Col>
    </Row>
    <List
      size="small"
      loading={store.isLoading}
      header={<div>관리 가능한 피처토글</div>}
      bordered
      dataSource={store.features}
      renderItem={(item) => <List.Item actions={[<Switch key="switch" defaultChecked={item.active} onChange={checked => handleOnChange(item.featureType, checked)} />]}>{item.featureType}</List.Item>}
    />
  </>);
};

export default observer(FeatureToggle);
