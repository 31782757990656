import {Button, Popconfirm} from "antd";
import React from "react";
import {CONTENTS_TYPE} from "../../constants/Values";
import moment from "moment/moment";

const MAX_DISPLAY_CONTENT_LENGTH = 32;
export const postColumns = (showPostModal, showComplaintModal, handleBlocking, handleComplete) => [
  {
    title: '게시글ID',
    dataIndex: 'targetId',
    key: 'targetId',
    width: 100,
    render: targetId => <Button type="link" onClick={() => showPostModal(targetId)}>{targetId}</Button>,
  },
  {
    title: '게시글 상태',
    dataIndex: 'post',
    key: 'state',
    render: post => post?.block ? <b style={{color: "red"}}>Blocked</b> : <b style={{color: "green"}}>Live</b>,
  },
  {
    title: '신고 제목',
    key: 'title',
    width: 150,
    render: data => <Button type="link" onClick={() => showComplaintModal(data.id)}>{data.title}</Button>
  },
  {
    title: '신고 내용 미리보기',
    dataIndex: 'content',
    key: 'content',
    width: 250,
    render: content => {
      if (content && content.length > MAX_DISPLAY_CONTENT_LENGTH) {
        content = content.substring(0, MAX_DISPLAY_CONTENT_LENGTH - 2) + '...';
      }
      return content || '-';
    },
  },
  {
    title: '신고자',
    dataIndex: 'accountId',
    key: 'accountId',
  },
  {
    title: '신고일',
    dataIndex: 'createdDate',
    key: 'createdDate',
    render: createdDate => moment(createdDate).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: '처리',
    key: 'action',
    render: data => <>
      <Popconfirm
        title="게시글 운영자 제재"
        description="승인할 경우 해당 게시글은 더이상 이용자에게 노출되지 않습니다."
        onConfirm={() => handleBlocking(data.targetId)}
        okText="승인"
        cancelText="취소"
      >
        <Button danger disabled={data.post.block}>제재</Button>
      </Popconfirm>
      <Button style={{marginLeft: '5px'}} onClick={() => handleComplete(data.id)}>확인완료</Button>
    </>,
  }
]

export const commentColumns = (showContentsModal, showComplaintModal, handleBlocking, handleComplete) => [
  {
    title: '게시글ID',
    dataIndex: 'comment',
    key: 'postId',
    width: 100,
    render: comment => <Button type="link" onClick={() => showContentsModal(comment.postId, CONTENTS_TYPE.POST)}>{comment.postId}</Button>,
  },
  {
    title: '댓글 상태',
    dataIndex: 'comment',
    key: 'state',
    render: comment => comment?.block ? <b style={{color: "red"}}>Blocked</b> : <b style={{color: "green"}}>Live</b>,
  },
  {
    title: '댓글ID',
    dataIndex: 'targetId',
    key: 'targetId',
    width: 100,
    render: targetId => <Button type="link" onClick={() => showContentsModal(targetId, CONTENTS_TYPE.COMMENT)}>{targetId}</Button>,
  },
  {
    title: '신고 제목',
    key: 'title',
    width: 150,
    render: data => <Button type="link" onClick={() => showComplaintModal(data.id)}>{data.title}</Button>
  },
  {
    title: '신고 내용 미리보기',
    dataIndex: 'content',
    key: 'content',
    width: 250,
    render: content => {
      if (content && content.length > MAX_DISPLAY_CONTENT_LENGTH) {
        content = content.substring(0, MAX_DISPLAY_CONTENT_LENGTH - 2) + '...';
      }
      return content || '-';
    },
  },
  {
    title: '신고자',
    dataIndex: 'accountId',
    key: 'accountId',
  },
  {
    title: '처리',
    key: 'action',
    render: data => <>
      <Button danger disabled={data.comment.block} onClick={() => handleBlocking(data.targetId)}>제재</Button>
      <Button style={{marginLeft: '5px'}} onClick={() => handleComplete(data.id)}>확인완료</Button>
    </>,
  }
]
