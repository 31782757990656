import React, {useEffect, useState} from 'react';
import ComplaintStore from "../ComplaintStore";
import {observer} from "mobx-react-lite";
import {Button, Modal, Card} from "antd";
import moment from "moment/moment";
import {STORE_STATE} from "../../../constants/Values";

const ContentsDetailModal = ({contentId, type, modalOpen, setModalOpen}) => {
  const [complaintStore] = useState(ComplaintStore());
  const [currentContents, setCurrentContents] = useState({});

  useEffect(() => {
    contentId && complaintStore.fetchComplainedContents(contentId, type).then(setCurrentContents);
  }, [contentId, type]);

  const closeModal = () => {
    setModalOpen(false);
  }

  return <>
    <Modal
      open={modalOpen}
      title="콘텐츠 상세보기"
      onOk={closeModal}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          확인
        </Button>,
      ]}
    >
      <Card bordered={true} loading={complaintStore.modalState === STORE_STATE.LOADING}>
        <Card.Meta title={currentContents?.content?.title} description={<>
          <b>{currentContents?.accountName}</b> / {currentContents?.accountDomain} / {moment(currentContents?.modifiedDate).format("YYYY-MM-DD HH:mm:ss")}
        </>}/>
        <div style={{
          marginTop: '1em',
          marginBottom: '0em',
          whiteSpace: 'pre-wrap'
        }}>{currentContents?.content?.content}</div>
        {currentContents?.content?.imageUrls?.length > 0 &&
            currentContents.content.imageUrls.map(((image, index) => {
              return <img key={index} src={image} style={{maxWidth: '200px', height: 'auto'}}/>
            }))
        }
      </Card>
    </Modal>
  </>;
};

export default observer(ContentsDetailModal);
