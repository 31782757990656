import React, {useEffect, useState} from 'react';
import ComplaintStore from "../ComplaintStore";
import {observer} from "mobx-react-lite";
import {Table, Col, Row, Pagination} from "antd";
import {CONTENTS_TYPE} from "../../../constants/Values";
import _ from "lodash";
import ComplaintModal from "../modal/ComplainModal";
import {commentColumns} from "../TableColumns";
import ContentsDetailModal from "../modal/ContentsDetailModal";

const Complaint = () => {
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [complaintStore] = useState(ComplaintStore());
  const [currentContentsId, setCurrentContentsId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [complaintModalOpen, setComplaintModalOpen] = useState(false);
  const [currentContentsType, setCurrentContentsType] = useState(CONTENTS_TYPE.COMMENT);
  const [currentComplaints, setCurrentComplaints] = useState();

  useEffect(() => {
    complaintStore.fetchComplaint(CONTENTS_TYPE.COMMENT, pagination);
  }, [pagination]);

  const handleCommentBlock = commentId => {
    complaintStore.blockingCommentByComplaint(commentId).then(() => {
      complaintStore.fetchComplaint(CONTENTS_TYPE.COMMENT, pagination);
    });
  }

  const handleComplete = id => {
    complaintStore.complete(id).then(() => {
      complaintStore.fetchComplaint(CONTENTS_TYPE.COMMENT, pagination);
    });
  }

  const showContentsModal = (postId, type) => {
    setCurrentContentsId(postId)
    setCurrentContentsType(type)
    setModalOpen(true);
  };

  const showComplaintModal = id => {
    setCurrentComplaints(_.find(complaintStore.complaints.data, {id}));
    setComplaintModalOpen(true);
  }

  return (<>
    <Row>
      <Col span={24}>
        <h2>신고 관리</h2>
        <p>신고된 댓글에 대한 제재 및 모니터링을 위한 페이지</p>
      </Col>
    </Row>
    <div style={{height: '40px'}} />
    <div className="card-container">
      <Table
        rowKey={data => data.id}
        columns={commentColumns(showContentsModal, showComplaintModal, handleCommentBlock, handleComplete)}
        dataSource={complaintStore.complaints.data}
        loading={complaintStore.isLoading}
        pagination={false}
      />
    </div>
    <div style={{marginTop: 10, textAlign: 'right'}}>
      <Pagination
        showSizeChanger
        pageSizeOptions={[10,20,30]}
        defaultPageSize={pagination.size}
        total={complaintStore.complaints?.totalCount}
        current={pagination.page + 1}
        onChange={(page, size) => {setPagination({page: page - 1, size})}}
      />
    </div>
    <ContentsDetailModal contentId={currentContentsId} type={currentContentsType} modalOpen={modalOpen} setModalOpen={setModalOpen}/>
    <ComplaintModal complain={currentComplaints} modalOpen={complaintModalOpen} setModalOpen={setComplaintModalOpen}/>
  </>);
};

export default observer(Complaint);
