import { action, computed, observable, runInAction } from 'mobx';
import { STORE_STATE } from "../../constants/Values";
import {
  sendNotifications,
} from "../../shared/CamlyApi";


const NotificationStore = () => observable.object({
  state: STORE_STATE.DEFAULT,

  get isLoading() {
    return this.state === STORE_STATE.LOADING;
  },

  async sendNotifications({ title, body, landingUrl, testAccountIds }) {
    this.state = STORE_STATE.LOADING;

    const testAccountIdsArray = testAccountIds ? testAccountIds.split(',').map(id => id.trim()) : null;
    const result = await sendNotifications({
      title,
      body,
      landingUrl,
      testAccountIds: testAccountIdsArray
    });
    this.state = STORE_STATE.DONE;
    return result;
  },


}, {
  isLoading: computed,
  sendNotifications: action.bound,
});

export default NotificationStore;
