import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Button, Col, message, Pagination, Popconfirm, Popover, Row, Table} from "antd";
import PromotionStore from "./PromotionStore";

const columns = (promotionType, handleDeleteConfirm) => {
  if (promotionType === 'NOTICE') {
    return [
      {
        title: '프로모션 ID',
        dataIndex: 'id',
      },
      {
        title: '타입',
        dataIndex: 'type',
      },
      {
        title: '타이틀',
        dataIndex: 'content',
        render: content => content.title
      },
      {
        title: '랜딩URL',
        dataIndex: 'content',
        render: content => <a target='_blank' rel='noreferrer' href={content.landingUrl}>{content.landingUrl}</a>,
      },
      {
        title: '처리',
        dataIndex: 'id',
        key: 'modify',
        render: id => <>
          <Popconfirm
              title="정말 해당 프로모션을 삭제 하시겠습니까?"
              onConfirm={() => {
                handleDeleteConfirm(id)
              }}
              okText="확인"
              cancelText="취소"
          >
            <Button danger style={{marginLeft: '5px'}}>삭제</Button>
          </Popconfirm>
        </>,
      },
    ];
  }

  if (promotionType === 'BANNER') {
    return [
      {
        title: '프로모션 ID',
        dataIndex: 'id',
      },
      {
        title: '타입',
        dataIndex: 'type',
      },
      {
        title: '이미지',
        dataIndex: 'content',
        render: content => <Popover
            content={
              <img src={content?.image?.url} style={{maxWidth: '300px', height: 'auto'}} />
            }
            trigger="hover">
          <div
              style={{
                width: 80,
                height: 80,
                border: '1px dashed #e8e8e8',
                borderRadius: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
            <img src={content?.image?.url} style={{maxWidth: '100%', maxHeight: '100%', height: 'auto'}} />
          </div>
        </Popover>,
      },
      {
        title: '랜딩URL',
        dataIndex: 'content',
        render: content => <a target='_blank' rel='noreferrer' href={content?.landingUrl}>{content?.landingUrl}</a>,
      }
    ];
  }
}

const Promotion = () => {
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [promotionStore] = useState(PromotionStore());
  const [promotionType, setPromotionType] = useState('NOTICE');

  useEffect(() => {
    promotionStore.fetchPromotion(promotionType, pagination);
  }, [pagination]);

  const handleDeleteConfirm = promotionId => {
    promotionStore.deletePromotion(promotionId)
        .then(() => {
          message.success({content: '프로모션 삭제 성공!', key: 100, duration: 4});
          promotionStore.fetchPromotion(promotionType, pagination);
        })
        .catch(() => message.error({content: '프로모션 삭제에 실패했습니다', key: 100, duration: 4}))
  }

  return <>
    <Row>
      <Col span={24}>
        <h2>프로모션 관리</h2>
        <p>프로모션 신규생성 및 관리를 위한 페이지</p>
      </Col>
    </Row>
    <div className="card-container">
      <Table
        rowKey={data => data.id}
        columns={columns(promotionType, handleDeleteConfirm)}
        dataSource={promotionStore.promotions?.data}
        loading={promotionStore.isLoading}
        pagination={false}
      />
    </div>
    <div style={{marginTop: 10, textAlign: 'right'}}>
      <Pagination
        showSizeChanger
        pageSizeOptions={[10,20,30]}
        defaultPageSize={pagination.size}
        total={promotionStore.promotions?.totalCount}
        current={pagination.page + 1}
        onChange={(page, size) => {setPagination({page: page - 1, size})}}
      />
    </div>
  </>;
};

export default observer(Promotion);
