import {action, computed, observable, runInAction} from 'mobx';
import {STORE_STATE} from "../../constants/Values";
import {getPromotions, deletePromotion} from "../../shared/CamlyApi";

const PromotionStore = () => observable.object({
  state: STORE_STATE.DEFAULT,
  promotions: {},

  get isLoading () {
    return this.state === STORE_STATE.LOADING;
  },

  async fetchPromotion(promotionType, pagination) {
    this.state = STORE_STATE.LOADING;
    const {data} = await getPromotions({promotionType}, pagination);
    runInAction(() => {
      this.promotions.data = data?.datas;
      this.promotions.totalCount = data?.paging?.totalCount;
    })
    this.state = STORE_STATE.DONE;
  },

  async deletePromotion(id) {
    this.state = STORE_STATE.LOADING;
    await deletePromotion(id);
    this.state = STORE_STATE.DONE;
  }

}, {
  isLoading: computed,
  fetchPromotion: action.bound,
  deletePromotion: action.bound,
});

export default PromotionStore;
