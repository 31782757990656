import {action, computed, observable, runInAction} from 'mobx';
import {STORE_STATE} from "../../constants/Values";
import {
  activateCategory,
  createCategory,
  getCategories,
  getUnlockedCategories,
  inactivateCategory,
  updateCategory
} from "../../shared/CamlyApi";

const CategoryStore = () => observable.object({
  state: STORE_STATE.DEFAULT,
  categories: [],

  get isLoading () {
    return this.state === STORE_STATE.LOADING;
  },

  async fetchCategory () {
    this.state = STORE_STATE.LOADING;
    const {data} = await getCategories();
    runInAction(() => {
      this.categories = data?.categories;
    })
    this.state = STORE_STATE.DONE;
  },

  async fetchUnlockedCategory () {
    this.state = STORE_STATE.LOADING;
    const {data} = await getUnlockedCategories();
    runInAction(() => {
      this.categories = data?.categories;
    })
    this.state = STORE_STATE.DONE;
  },

  async createCategory (req) {
    this.state = STORE_STATE.LOADING;
    await createCategory(req);
    await this.fetchCategory();
  },

  async updateCategory (req) {
    this.state = STORE_STATE.LOADING;
    await updateCategory(req.id, req);
    await this.fetchCategory();
  },

  async updateCategoryUsable (id, isUsable) {
    this.state = STORE_STATE.LOADING;
    if (isUsable) {
      await activateCategory(id);
    } else {
      await inactivateCategory(id);
    }
    await this.fetchCategory();
  },
}, {
  isLoading: computed,
  fetchCategory: action.bound,
  fetchUnlockedCategory: action.bound,
  createCategory: action.bound,
  updateCategory: action.bound,
  updateCategoryUsable: action.bound,
});

export default CategoryStore;
