import {action, computed, observable, runInAction} from 'mobx';
import {STORE_STATE} from "../../../constants/Values";
import {getBlockedAccounts, blockAccount, unblockAccount} from "../../../shared/CamlyApi";

const AccountBlockStore = () => observable.object({
  state: STORE_STATE.DEFAULT,
  blockedAccount: {},

  get isLoading () {
    return this.state === STORE_STATE.LOADING;
  },

  async fetchBlockedAccounts (pagination) {
    this.state = STORE_STATE.LOADING;
    const {data} = await getBlockedAccounts(pagination);
    runInAction(() => {
      this.blockedAccount.data = data.datas;
      this.blockedAccount.totalCount = data.paging?.totalCount;
    })
    this.state = STORE_STATE.DONE;
  },

  async block(req) {
    try {
      await blockAccount(req);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async unblock(accountId) {
    try {
      await unblockAccount(accountId);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}, {
  isLoading: computed,
  fetchBlockedAccounts: action.bound,
  block: action.bound,
  unblock: action.bound,
});

export default AccountBlockStore;
