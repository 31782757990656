import {action, computed, observable, runInAction} from 'mobx';
import {STORE_STATE} from "../../constants/Values";
import {
  createNotice,
  getPost,
  updateNotice,
  deleteNotice,
  getPosts,
  createNoticePromotion,
} from "../../shared/CamlyApi";

const NOTICE_CATEGORY_ID = 4;

const NoticeStore = () => observable.object({
  state: STORE_STATE.DEFAULT,
  notice: {},

  get isLoading () {
    return this.state === STORE_STATE.LOADING;
  },

  async fetchNotice(categoryId, pagination) {
    this.state = STORE_STATE.LOADING;
    const {data} = await getPosts(categoryId, pagination);
    runInAction(() => {
      this.notice.data = data?.datas;
      this.notice.totalCount = data.paging?.totalCount;
    });
    this.state = STORE_STATE.DONE;
  },

  async fetchNoticeById(noticeId) {
    this.state = STORE_STATE.LOADING;
    const {data: notice} = await getPost(noticeId);
    this.state = STORE_STATE.DONE;
    return notice;
  },

  async createNotice({title, content, publishNotification}) {
    this.state = STORE_STATE.LOADING;
    await createNotice({
      title,
      content,
      publishNotification,
    });
    this.state = STORE_STATE.DONE;
  },

  async updateNotice(noticeId, {title, content}) {
    this.state = STORE_STATE.LOADING;
    await updateNotice(noticeId, {
      title,
      content,
    });
    this.state = STORE_STATE.DONE;
  },

  async deleteNotice(noticeId) {
    this.state = STORE_STATE.LOADING;
    await deleteNotice(noticeId);
    this.state = STORE_STATE.DONE;
  },

  async createPromotion(values) {
    this.state = STORE_STATE.LOADING;
    await createNoticePromotion(values);
    this.state = STORE_STATE.DONE;
    return true;
  }

}, {
  isLoading: computed,
  fetchNotice: action.bound,
  createNotice: action.bound,
  updateNotice: action.bound,
  deleteNotice: action.bound,
  createPromotion: action.bound,
});

export default NoticeStore;
