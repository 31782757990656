import React from 'react';
import {observer} from "mobx-react-lite";
import {Button, Modal, Card} from "antd";
import moment from "moment";

const ComplaintModal = ({complain, modalOpen, setModalOpen}) => {
  const closeComplaintModal = () => {
    setModalOpen(false);
  }

  return <Modal
    open={modalOpen}
    title="신고내용 상세보기"
    onOk={closeComplaintModal}
    onCancel={closeComplaintModal}
    footer={[
      <Button key="back" onClick={closeComplaintModal}>
        확인
      </Button>,
    ]}
  >
    <div
      id="scrollableDiv"
      style={{
        maxHeight: 700,
        overflow: 'auto',
        padding: '0 16px',
      }}
    >
      {complain && <Card key={complain.id} style={{padding: '10px'}}>
        <Card.Meta title={complain.title} description={moment(complain.createdDate).format("YYYY-MM-DD HH:mm:ss")} />
        <div style={{marginTop: '1em', marginBottom: '0em', whiteSpace: 'pre-wrap'}}>{complain.content || '-'}</div>
      </Card>}
    </div>
  </Modal>;
};

export default observer(ComplaintModal);
