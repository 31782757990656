import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {Button, Col, Input, message, Row, Table} from "antd";
import AccountHideStore from "./AccountHideStore";

const columns = (handleUnHide) => [
  {
    title: '이용자 계정',
    dataIndex: 'accountId',
    key: 'accountId'
  },
  {
    title: '숨기기 처리한 계정',
    dataIndex: 'hiddenAccountId',
    key: 'hiddenAccountId',
  },
  {
    title: '부가기능',
    key: 'stop',
    render: data => {
      return <Button onClick={() => handleUnHide(data.accountId, data.hiddenAccountId)}>숨기기 해제</Button>;
    }
  },
]

const AccountHide = () => {
  const [accountHideStore] = useState(AccountHideStore());

  const handleUnHide = async (accountId, hiddenAccountId) => {
    const result = await accountHideStore.deleteHideMapping(accountId, hiddenAccountId)
    if (result) {
      await accountHideStore.fetchHideAccounts(accountId);
      message.info({content: '사용자 숨기기 해제에 성공했습니다.', key: 104, duration: 4});
    } else {
      message.error({content: '사용자 숨기기 해제에 실패했습니다.', key: 104, duration: 4});
    }
  }

  const getHideAccountBy = async accountId => {
    await accountHideStore.fetchHideAccounts(accountId);
  }

  return (<>
    <Row>
      <Col span={24}>
        <h2>이용자 숨기기 관리</h2>
        <p>이용자가 숨기기한 계정을 조회하고 제거할 수 있는 페이지</p>
      </Col>
    </Row>
    <div style={{height: '40px', textAlign: 'right'}}>
      <Input.Search placeholder="이용자의 AccountId를 입력하세요." onSearch={getHideAccountBy} enterButton style={{ width: 300 }}/>
    </div>
    <div className="card-container">
      <Table
        rowKey={data => data.hiddenAccountId}
        columns={columns(handleUnHide)}
        dataSource={accountHideStore.hideAccount}
        loading={accountHideStore.isLoading}
        pagination={false}
      />
    </div>
  </>);
};

export default observer(AccountHide);
