import { action, computed, observable, runInAction } from 'mobx';
import { STORE_STATE } from "../../constants/Values";
import { getInsightGraphs, getInsightDau, getInsightRetainedAccounts } from "../../shared/CamlyApi";
import _ from "lodash";

const ReportStore = () => observable.object({
  state: STORE_STATE.DEFAULT,
  postData: [],
  commentData: [],
  accountData: [],
  mergedData: [],
  dauData: [],
  retainData: [],

  get isLoading () {
    return this.state === STORE_STATE.LOADING;
  },

  async fetchReports(unit, startDate, endDate) {
    this.state = STORE_STATE.LOADING;

    const {data: postSummary} = await getInsightGraphs("POST", _.toUpper(unit), startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    const {data: commentSummary} = await getInsightGraphs("COMMENT", _.toUpper(unit), startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    const {data: accountSummary} = await getInsightGraphs("ACCOUNT", _.toUpper(unit), startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    const {data: dauSummary} = await getInsightDau();
    const {data: retainedAccountSummary} = await getInsightRetainedAccounts();


    runInAction(() => {
      this.postData = postSummary;
      this.commentData = commentSummary;
      this.accountData = accountSummary;
      this.mergeData();
      this.dauData = dauSummary;
      this.retainData = retainedAccountSummary;
    })
    this.state = STORE_STATE.DONE;
  },

  mergeData() {
    const dataMap = {};

    [this.postData, this.accountData, this.commentData].forEach((data, index) => {
      data.forEach(item => {
        const date = item.date;
        if (!dataMap[date]) {
          dataMap[date] = {
            date,
            postCount: 0, postCumulativeCount: 0,
            accountCount: 0, accountCumulativeCount: 0,
            commentCount: 0, commentCumulativeCount: 0,

          };
        }
        if (index === 0) {
          dataMap[date].postCount = item.count;
          dataMap[date].postCumulativeCount = item.cumulativeCount;
        } else if (index === 1) {
          dataMap[date].accountCount = item.count;
          dataMap[date].accountCumulativeCount = item.cumulativeCount;
        } else {
          dataMap[date].commentCount = item.count;
          dataMap[date].commentCumulativeCount = item.cumulativeCount;
        }
      });
    });

    this.mergedData = Object.values(dataMap).sort((a, b) => new Date(b.date) - new Date(a.date));
  },

}, {
  isLoading: computed,
  fetchReports: action.bound,
});

export default ReportStore;
