import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {createRoot} from 'react-dom/client';
import 'antd/dist/reset.css';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

console.log(`current phase: ${process.env.REACT_APP_PHASE}`);

root.render(
  <BrowserRouter getUserConfirmation={((message, callback) => {
    callback(window.confirm(message));
  })}>
    <App/>
  </BrowserRouter>
);
