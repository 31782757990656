import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Modal, Form, Input, Button, message } from "antd";
import { CODE_TYPE } from "../../../../constants/Values";
import S3Uploader from "../../../../shared/S3Uploader";

const UpdateDomainModal = ({ updateModal, setUpdateModal, update, reload }) => {
  const [form] = Form.useForm();
  const [modalLoading, setModalLoading] = useState(false);
  const [univImageUrl, setUnivImageUrl] = useState(null);

  useEffect(() => {
    form.setFieldsValue({
      code: updateModal.currentDomain?.code,
      message: updateModal.currentDomain?.content?.message,
      redirectCode: updateModal.currentDomain?.content?.redirectCode
    })
  }, [updateModal.currentDomain])

  const modalClose = () => {
    setUpdateModal({
      show: false,
      currentDomain: {},
    });
    setUnivImageUrl(null);
  }

  const updateDomain = async values => {
    setModalLoading(true);
    const result = await update(CODE_TYPE.DOMAIN, updateModal.currentDomain.id, values.code, {
      message: values.message,
      imageUrl: univImageUrl,
      redirectCode: values.redirectCode === "" ? null : values.redirectCode
    });
    setModalLoading(false);
    if (result) {
      modalClose();
      reload(false);
      message.info({ content: '도메인 수정에 성공했습니다.', key: 104, duration: 4 });
    } else {
      message.error({ content: '도메인 수정에 실패했습니다.', key: 104, duration: 4 });
    }
  }

  return <Modal
    open={updateModal?.show}
    title="도메인 수정하기"
    onCancel={modalClose}
    footer={[
      <Button key="back" onClick={modalClose}>
        취소
      </Button>,
      <Button key="ok" loading={modalLoading} onClick={form.submit}>
        저장
      </Button>,
    ]}
  >
    <div>
      <Form form={form} name="update" onFinish={updateDomain}>
        <Form.Item label="Code" name="code">
          <Input disabled placeholder="등록할 대학교의 도메인" />
        </Form.Item>
        <Form.Item label="Display Name" name="message">
          <Input placeholder="클라이언트에 노출할 이름" />
        </Form.Item>
        <Form.Item label="Redirect Code" name="redirectCode">
          <Input placeholder="Redirect Code" />
        </Form.Item>
      </Form>
    </div>
    <S3Uploader
      univImageUrl={univImageUrl}
      onUploadReady={imageUrl => {
        setUnivImageUrl(imageUrl);
      }}
    />
  </Modal>;
};

export default observer(UpdateDomainModal);
