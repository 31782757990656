import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {authentication} from "../auth/Authentication";
import BasicLayout from "./BasicLayout";

export const AuthLayout = () => {
  const {token, user} = authentication;
  if (!token || !user) {
    authentication.logout();
    return <Navigate to={"/login"} replace />;
  }
  return <BasicLayout><Outlet /></BasicLayout>;
}
