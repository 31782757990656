import React from 'react';
import { Route, Routes } from 'react-router-dom';
import axiosConfig from './shared/AxiosConfig';
import Login from './pages/login'
import Home from "./pages/home";
import { AuthLayout } from "./layout/AuthLayout";
import PostComplaint from "./pages/complaint/Post";
import CommentComplaint from "./pages/complaint/Comment";
import Notice from "./pages/notice";
import UpsertForm from "./pages/notice/UpsertForm";
import Emoji from "./pages/emoji";
import AccountBlock from "./pages/account/Block";
import AccountHide from "./pages/account/Hide";
import Maintenance from "./pages/maintenance";
import Category from "./pages/category";
import FeatureToggle from "./pages/featuretoggle";
import Promotion from "./pages/promotion";
import Domain from "./pages/code/Domain";
import Notification from './pages/notification';

const App = () => {
  axiosConfig.setDefaultAxiosSetting();

  return <Routes>
    <Route exact path="/login" element={<Login />} />
    <Route element={<AuthLayout />}>
      <Route path="/complain/post" element={<PostComplaint />} />
      <Route path="/complain/comment" element={<CommentComplaint />} />
      <Route path="/notice/upsert" element={<UpsertForm />} />
      <Route path="/notice" element={<Notice />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/emoji" element={<Emoji />} />
      <Route path="/account/block" element={<AccountBlock />} />
      <Route path="/account/hide" element={<AccountHide />} />
      <Route path="/maintenance" element={<Maintenance />} />
      <Route path="/category" element={<Category />} />
      <Route path="/feature-toggle" element={<FeatureToggle />} />
      <Route path="/promotion" element={<Promotion />} />
      <Route path="/code/domain" element={<Domain />} />
      <Route path="/" element={<Home />} />
    </Route>
    {/*<Route path="/" element={<Navigate replace to="/login" />} />*/}
  </Routes>
}

export default App;
