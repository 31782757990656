import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Button, Col, DatePicker, Form, Input, message, Modal, Pagination, Row, Table} from "antd";
import moment from "moment/moment";
import MaintenanceStore from "./MaintenanceStore";

const columns = (handleImmediateTermination) => [
  {
    title: '점검 시작일시',
    dataIndex: 'startDate',
    key: 'startDate',
    render: startDate => moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: '점검 종료일시',
    dataIndex: 'endDate',
    key: 'endDate',
    render: endDate => moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '부가기능',
    key: 'stop',
    render: data => {
      const now = moment.now();
      const isBefore = moment(now).isBefore(data.endDate);
      return <Button disabled={!isBefore} onClick={() => handleImmediateTermination(data.id)}>즉시 종료</Button>;
    }
  },
]

const Maintenance = () => {
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [form] = Form.useForm();
  const [maintenanceStore] = useState(MaintenanceStore());
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    maintenanceStore.fetchMaintenance(pagination);
  }, [pagination]);

  const showModal = () => {
    form.resetFields();
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSave = async values => {
    setModalLoading(true);
    const title = values['title'];
    const rangeTimeValue = values['range-time-picker'];

    const result = await maintenanceStore.createMaintenance({
      title,
      startDate: rangeTimeValue[0].format('YYYY-MM-DDTHH:mm:ss+09:00'),
      endDate: rangeTimeValue[1].format('YYYY-MM-DDTHH:mm:ss+09:00'),
    })
    setModalLoading(false);
    if (result) {
      closeModal();
      await maintenanceStore.fetchMaintenance(pagination);
      message.info({content: '서비스 점검 등록에 성공했습니다.', key: 104, duration: 4});
    } else {
      message.error({content: '서비스 점검 등록을 실패했습니다.', key: 104, duration: 4});
    }
  };

  const handleImmediateTermination = async id => {
    const result = await maintenanceStore.deleteMaintenance(id)
    if (result) {
      maintenanceStore.fetchMaintenance(pagination);
      message.info({content: '서비스 점검 즉시종료에 성공했습니다.', key: 104, duration: 4});
    } else {
      message.error({content: '서비스 점검 즉시종료에 실패했습니다.', key: 104, duration: 4});
    }
  }

  return (<>
    <Row>
      <Col span={24}>
        <h2>서비스 점검 관리</h2>
        <p>서비스 점검 기록과 신규 점검 생성을 위한 페이지</p>
      </Col>
    </Row>
    <div style={{height: '40px', textAlign: 'right'}}>
      <Button onClick={showModal}>새 서비스 점검 만들기</Button>
    </div>
    <div className="card-container">
      <Table
        rowKey={data => data.id}
        columns={columns(handleImmediateTermination)}
        dataSource={maintenanceStore.maintenance?.data}
        loading={maintenanceStore.isLoading}
        pagination={false}
      />
    </div>
    <div style={{marginTop: 10, textAlign: 'right'}}>
      <Pagination
        showSizeChanger
        pageSizeOptions={[10,20,30]}
        defaultPageSize={pagination.size}
        total={maintenanceStore.maintenance?.totalCount}
        current={pagination.page + 1}
        onChange={(page, size) => {setPagination({page: page - 1, size})}}
      />
    </div>
    <Modal
      width={600}
      open={modalOpen}
      title="점검 생성하기"
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          취소
        </Button>,
        <Button key="ok" loading={modalLoading} onClick={form.submit}>
          저장
        </Button>,
      ]}
    >
      <div>
        <Form form={form} name="register" onFinish={handleSave}>
          <Form.Item name="title" label="작업명"
                     rules={[
                       {
                         required: true,
                         message: '작업명은 필수값 입니다.',
                       },
                     ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="range-time-picker" label="점검 기간"
                     rules={[
                       {
                         type: 'array',
                         required: true,
                         message: 'Please select time!',
                       },
                     ]}
          >
            <DatePicker.RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  </>);
};

export default observer(Maintenance);
