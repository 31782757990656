import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Button, message, Transfer} from "antd";
import EmojiStore from "./EmojiStore";
import _ from 'lodash'
import EmojiCreateModal from "./EmojiCreateModal";

const PostEmoji = () => {
  const [emojiStore] = useState(EmojiStore());
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    fetchWithSetTargetKeys()
  }, [])

  const fetchWithSetTargetKeys = () => {
    emojiStore.fetchPostEmojis().then(() => {
      setTargetKeys(_.filter(emojiStore.postEmojis, {usable: true}).map(e => e.id))
    });
  }

  const handleAddNewEmoji = (code) => {
    emojiStore.createNewEmoji(code)
      .then(() => {
        fetchWithSetTargetKeys()
        message.success({content: '이모지 추가 성공!', key: 100, duration: 4})
      })
      .catch(() => message.error({content: '이모지 추가에 실패했습니다.', key: 100, duration: 4}))
  }

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleConfirm = () => {
    const unUsableTargetKeys = emojiStore.postEmojis.filter(e => _.indexOf(targetKeys, e.id) === -1).map(i => i.id);
    Promise.all([
      emojiStore.updateEmojiUsable(targetKeys, true),
      emojiStore.updateEmojiUsable(unUsableTargetKeys, false)
    ]).then(() => {
      fetchWithSetTargetKeys()
      message.success({content: '이모지 설정 저장 성공!', key: 100, duration: 4})
    }).catch(() => message.error({content: '이모지 설정 저장에 실패했습니다.', key: 100, duration: 4}))

  }

  return (<>
    <div style={{textAlign: 'right', marginTop: '20px', marginBottom: '20px'}}>
      <Button onClick={() => setModalOpen(true)} style={{marginRight: '10px'}}>이모티콘 신규추가</Button>
      <Button type='primary' onClick={handleConfirm}>저장</Button>
    </div>
    <Transfer
      dataSource={emojiStore.postEmojis}
      titles={['미사용', '운영중']}
      targetKeys={targetKeys}
      selectedKeys={selectedKeys}
      onChange={onChange}
      onSelectChange={onSelectChange}
      render={(item) => item.code}
    />
    <EmojiCreateModal modalOpen={modalOpen} setModalOpen={setModalOpen} handleAddNewEmoji={handleAddNewEmoji}/>
  </>);
};

export default observer(PostEmoji);
