import {action, computed, observable, runInAction} from 'mobx';
import {STORE_STATE} from "../../constants/Values";
import {
  createCode,
  deleteCode,
  updateCode,
  getCodes
} from "../../shared/CamlyApi";

const CodeStore = () => observable.object({
  state: STORE_STATE.DEFAULT,
  codes: {},

  get isLoading () {
    return this.state === STORE_STATE.LOADING;
  },

  async fetchCode (type, pagination) {
    this.state = STORE_STATE.LOADING;
    let {data} = await getCodes(type, pagination);
    runInAction(() => {
      this.codes.data = data.datas;
      this.codes.totalCount = data.paging?.totalCount;
    })
    this.state = STORE_STATE.DONE;
  },

  async createCode (type, code, content) {
    try {
      await createCode({
        type, code, content
      });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async deleteCode (id) {
    try {
      await deleteCode(id);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async updateCode (type, id, code, content) {
    try {
      await updateCode(id, {
        type, code, content
      });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

}, {
  isLoading: computed,
  fetchCode: action.bound,
  createCode: action.bound,
  deleteCode: action.bound,
  updateCode: action.bound,
});

export default CodeStore;
