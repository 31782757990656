import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Button, Transfer} from "antd";
import EmojiStore from "./EmojiStore";
import _ from 'lodash'
import EmojiCreateModal from "./EmojiCreateModal";

const CommentEmoji = () => {
  const [emojiStore] = useState(EmojiStore());
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    emojiStore.fetchCommentEmojis().then(() => {
      const map = _.filter(emojiStore.commentEmojis, {usable: true}).map(e => e.id);
      setTargetKeys(map)
    });
  }, [])

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  return (<>
    <div style={{textAlign: 'right', marginTop: '20px', marginBottom: '20px'}}>
      <Button type='primary' disabled>저장</Button>
    </div>
    <Transfer
      dataSource={emojiStore.commentEmojis}
      titles={['미사용', '운영중']}
      targetKeys={targetKeys}
      selectedKeys={selectedKeys}
      onChange={onChange}
      onSelectChange={onSelectChange}
      render={(item) => item.code}
    />
    <EmojiCreateModal modalOpen={modalOpen} setModalOpen={setModalOpen}/>
  </>);
};

export default observer(CommentEmoji);
