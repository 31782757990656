import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {STORE_STATE} from "../../constants/Values";
import NoticeStore from "./NoticeStore";
import {Button, Card, Checkbox, Col, Form, Input, message, Row} from "antd";
import {useNavigate, useSearchParams} from "react-router-dom";

const UpsertForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [noticeStore] = useState(NoticeStore());
  const [noticeId, setNoticeId] = useState(undefined);
  const [currentNotice, setCurrentNotice] = useState();

  useEffect(() => {
    const noticeId = searchParams.get('noticeId');
    if (noticeId) {
      setNoticeId(noticeId);
      noticeStore.fetchNoticeById(noticeId).then(setCurrentNotice)
    }
  }, [])

  const onFinish = (values) => {
    if (noticeId) {
      noticeStore.updateNotice(noticeId, values)
        .then(() => message.success({content: '공지사항 수정 성공!', key: 100, duration: 4}))
        .catch(() => message.error({content: '공지사항 수정에 실패했습니다.', key: 100, duration: 4}))
    } else {
      noticeStore.createNotice(values)
        .then(() => message.success({content: '신규 공지사항 게시 성공!', key: 100, duration: 4}))
        .catch(() => message.error({content: '신규 공지사항 게시에 실패했습니다.', key: 100, duration: 4}))
    }
    navigate('/notice');
  };

  return (<>
    <Row>
      <Col span={24}>
        <h2>공지사항 관리</h2>
        <p>공지사항 생성/수정을 위한 페이지</p>
      </Col>
    </Row>
    <div style={{height: '20px', textAlign: 'right'}} />
    <Card title={currentNotice ? '글수정' : '신규생성'} loading={noticeStore.state === STORE_STATE.LOADING}>
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        initialValues={{ title: currentNotice?.content.title, content:  currentNotice?.content.content}}
        onFinish={onFinish}
      >
        <Form.Item
          label="제목"
          name="title"
          rules={[{ required: true, message: '제목은 필수사항 입니다.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="내용"
          name="content"
          rules={[{ required: true, message: '내용은 필수사항 입니다.' }]}
        >
          <Input.TextArea rows={6}/>
        </Form.Item>

        {noticeId === undefined && <>
          <Form.Item
            label="알림 발송 여부"
            name="publishNotification"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </>}

        <Form.Item wrapperCol={{ offset: 10, span: 12 }} shouldUpdate>
          <Button onClick={() => navigate('/notice')} style={{marginRight: '5px'}}>
            취소
          </Button>
          <Button type="primary" htmlType="submit" style={{marginLeft: '5px'}}>
            저장
          </Button>
        </Form.Item>
      </Form>
    </Card>
  </>);
};

export default observer(UpsertForm);
