import {action, computed, observable, runInAction} from 'mobx';
import {STORE_STATE} from "../../constants/Values";
import {getFeatureToggles, updateFeatureToggle} from "../../shared/CamlyApi";

const FeatureToggleStore = () => observable.object({
  state: STORE_STATE.DEFAULT,
  features: [],

  get isLoading () {
    return this.state === STORE_STATE.LOADING;
  },

  async fetchFeatureToggle () {
    this.state = STORE_STATE.LOADING;
    const {data} = await getFeatureToggles();
    runInAction(() => {
      this.features = data?.v1FeatureToggles;
    })
    this.state = STORE_STATE.DONE;
  },

  async updateFeatureToggle (req) {
    this.state = STORE_STATE.LOADING;
    await updateFeatureToggle(req);
    await this.fetchFeatureToggle();
  }

}, {
  isLoading: computed,
  fetchFeatureToggle: action.bound,
  updateFeatureToggle: action.bound,
});

export default FeatureToggleStore;
