import React from "react";
import { Button, Popconfirm, Popover } from "antd";

export const domainColumns = (showUpdateModal, deleteCode) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 100,
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    width: 300,
    render: data => <>{data}</>
  },
  {
    title: 'Display Name',
    dataIndex: 'content',
    key: 'message',
    render: data => <>{data.message}</>
  },
  {
    title: 'Code Image',
    dataIndex: 'content',
    key: 'imageUrl',
    render: data => <>{data.imageUrl ? <Popover
      content={
        <img src={data.imageUrl} style={{ maxWidth: '200px', height: 'auto' }} />
      }
      trigger="hover">
      <div
        style={{
          width: 50,
          height: 50,
          border: '1px dashed #e8e8e8',
          borderRadius: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <img src={data.imageUrl} style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto' }} />
      </div>
    </Popover> : '-'}</>
  },
  {
    title: 'Redirect Code',
    dataIndex: 'content',
    key: 'redirectCode',
    render: data => <>{data.redirectCode}</>
  },
  {
    title: '처리',
    key: 'action',
    render: data => <>
      <Button style={{ marginRight: 10 }} onClick={() => showUpdateModal(data)}>수정</Button>
      <Popconfirm
        title="도메인 삭제"
        description="승인할 경우 해당 도메인은 더이상 사용하지 못하게 됩니다. 신중한 선택이 필요합니다."
        onConfirm={() => deleteCode(data.id)}
        okText="승인"
        cancelText="취소"
      >
        <Button danger>삭제</Button>
      </Popconfirm>
    </>
  }
]
