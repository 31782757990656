import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Table, Col, Row, Pagination, Button, message} from "antd";
import {CODE_TYPE} from "../../../constants/Values";
import {domainColumns} from "../TableColumns";
import CodeStore from "../CodeStore";
import CreateDomainModal from "./modal/CreateDomainModal";
import UpdateDomainModal from "./modal/UpdateDomainModal";

const Domain = () => {
  const [pagination, setPagination] = useState({
    page: 0,
    size: 500,
  });
  const [codeStore] = useState(CodeStore());
  const [showModal, setShowModal] = useState(false);
  const [updateModal, setUpdateModal] = useState({show: false, currentDomain: {}});

  useEffect(() => {
    codeStore.fetchCode(CODE_TYPE.DOMAIN, pagination);
  }, [pagination]);

  const initReload = (resetPaging = true) => {
    const resetPagination = {
      page: 0,
      size: 10,
    }
    resetPaging && setPagination(resetPagination);
    codeStore.fetchCode(CODE_TYPE.DOMAIN, resetPaging ? resetPagination : pagination);
  }

  const handleDomainDelete = async id => {
    const result = await codeStore.deleteCode(id);
    if (result) {
      initReload(false);
      message.info({content: '도메인 삭제에 성공했습니다.', key: 102, duration: 4});
    } else {
      message.error({content: '도메인 삭제에 실패했습니다.', key: 102, duration: 4});
    }
  }

  const showUpdateModal = domain => {
    setUpdateModal({
      show: true,
      currentDomain: domain,
    });
  }

  return (<>
    <Row>
      <Col span={24}>
        <h2>도메인 관리</h2>
        <p>대학교별 도메인 관리를 위한 페이지</p>
      </Col>
    </Row>
    <div style={{height: '40px', textAlign: 'right'}}>
      <Button onClick={() => setShowModal(true)}>신규 도메인 생성</Button>
    </div>
    <div className="card-container">
      <Table
        rowKey={data => data.id}
        columns={domainColumns(showUpdateModal, handleDomainDelete)}
        dataSource={codeStore.codes.data}
        loading={codeStore.isLoading}
        pagination={false}
      />
    </div>
    <div style={{marginTop: 10, textAlign: 'right'}}>
      <Pagination
        showSizeChanger
        pageSizeOptions={[10,20,30,500]}
        defaultPageSize={pagination.size}
        total={codeStore.codes?.totalCount}
        current={pagination.page + 1}
        onChange={(page, size) => {setPagination({page: page - 1, size})}}
      />
    </div>
    <CreateDomainModal modalOpen={showModal} setModalOpen={setShowModal} register={codeStore.createCode} reload={initReload}/>
    <UpdateDomainModal forceRender updateModal={updateModal} setUpdateModal={setUpdateModal} update={codeStore.updateCode} reload={initReload}/>
  </>);
};

export default observer(Domain);
