import React from 'react';
import {observer} from "mobx-react-lite";
import {Col, Divider, Row} from "antd";
import PostEmojiTransfer from "./PostEmojiTransfer";
import CommentEmojiTransfer from "./CommentEmojiTransfer";

const Emoji = () => {
  return (<>
    <Row>
      <Col span={24}>
        <h2>이모티콘 관리</h2>
        <p>이모티콘 생성/조회/수정/삭제를 위한 페이지</p>
      </Col>
    </Row>
    <Row>
      <Col span={12} style={{display: 'flex'}}>
        <div style={{margin: 'auto'}}>
          <Divider orientation="left">게시글 이모티콘</Divider>
          <PostEmojiTransfer />
        </div>
      </Col>
      <Col span={12} style={{display: 'flex'}}>
        <div style={{margin: 'auto'}}>
          <Divider orientation="left">댓글 피드백</Divider>
          <CommentEmojiTransfer />
        </div>
      </Col>
    </Row>
  </>);
};

export default observer(Emoji);
