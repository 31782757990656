import axios from "axios";

axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;

export const healthCheck = () => axios.get('/management/health');

export const getComplaints = (type, pagination) => axios.get(`/v1/internal/complaints`, {
  params: {
    type,
    ...pagination
  }
});

export const getPost = postId => axios.get(`/v1/internal/posts/${postId}`);

export const getComment = commentId => axios.get(`/v1/internal/comments/${commentId}`);

export const blockingPost = postId => axios.patch(`/v1/internal/posts/block/${postId}`);

export const completeComplaint = complaintId => axios.patch(`/v1/internal/complaints/${complaintId}`);

export const blockingComment = commentId => axios.patch(`/v1/internal/comments/block/${commentId}`);

export const unblockingComment = commentId => axios.patch(`/v1/internal/comments/unblock/${commentId}`);

export const getPosts = (categoryId, pagination) => axios.get(`/v1/internal/posts`, {
  params: {
    categoryId,
    ...pagination
  }
});

export const createNotice = notice => axios.post(`/v1/internal/posts/notice/create`, notice);

export const updateNotice = (postId, notice) => axios.patch(`/v1/internal/posts/notice/${postId}`, notice);

export const deleteNotice = postId => axios.delete(`/v1/internal/posts/notice/${postId}`);

export const createNoticePromotion = values => axios.post('/v1/internal/promotions/notice', values)

export const getEmojis = () => axios.get('/v1/internal/emojis');

export const createEmoji = emoji => axios.post('/v1/internal/emojis', emoji);

export const updateEmojiUsable = emojisUsable => axios.patch('/v1/internal/emojis', emojisUsable);

export const getBlockedAccounts = pagination => axios.get('v1/internal/accounts/block', {
  params: pagination
});

export const blockAccount = block => axios.post('v1/internal/accounts/block', block);

export const unblockAccount = accountId => axios.patch(`v1/internal/accounts/unblock/${accountId}`);

export const getMaintenance = pagination => axios.get('/v1/internal/maintenances', {
  params: pagination
});

export const createMaintenance = values => axios.post('/v1/internal/maintenances', values);

export const deleteMaintenance = id => axios.delete(`/v1/internal/maintenances/${id}`);

export const getHideAccount = accountId => axios.get(`/v1/internal/accounts/hide-account`, {
  params: {
    accountId,
  }
});

export const deleteHideAccount = (accountId, hiddenAccountId) => axios.delete(`/v1/internal/accounts/hide-account?accountId=${accountId}&hiddenAccountId=${hiddenAccountId}`);

export const getCategories = () => axios.get(`/v1/internal/categories`);

export const getUnlockedCategories = () => axios.get(`/v1/internal/categories/unlock`);

export const createCategory = req => axios.post(`/v1/internal/categories`, req);

export const updateCategory = (id, req) => axios.patch(`/v1/internal/categories/${id}`, req);

export const activateCategory = id => axios.patch(`/v1/internal/categories/${id}/activate`);

export const inactivateCategory = id => axios.patch(`/v1/internal/categories/${id}/inactivate`);

export const getFeatureToggles = () => axios.get(`/v1/internal/feature-toggles`);

export const updateFeatureToggle = req => axios.post('/v1/internal/feature-toggles', req);

export const getPromotions = (req, pagination) => axios.get('/v1/internal/promotions', {
  params: {
    ...req,
    pagination,
  }
});

export const deletePromotion = id => axios.delete(`/v1/internal/promotions/${id}`);

export const getCodes = (codeType, pagination) => axios.get(`/v1/internal/codes`, {
  params: {
    type: codeType,
    ...pagination
  }
});

export const createCode = req => axios.post('/v1/internal/codes', req);

export const deleteCode = id => axios.delete(`/v1/internal/codes/${id}`);

export const updateCode = (id, req) => axios.post(`/v1/internal/codes/${id}`, req);

export const getInsightGraphs = (domainType, unit, start, end) => axios.get(`/v1/internal/insight/graphs?domainType=${domainType}&unit=${unit}&start=${start}&end=${end}`);

export const getInsightEmailDomain = () => axios.get(`/v1/internal/insight/email-domain`);

export const getInsightDau = () => axios.get(`/v1/internal/insight/dau`);

export const getInsightRetainedAccounts = () => axios.get(`/v1/internal/insight/retained-accounts`);

export const getUnivPreSignedImageUrl = () => axios.post(`/v1/internal/multimedia/univ/upload-presigned-url`, {
  format: 'JPEG'
});

export const sendNotifications = req => axios.post(`/v1/internal/notifications/send`, req);
