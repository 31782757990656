import React, { useEffect, useState } from 'react';
import {
  CheckCircleTwoTone, CloseCircleTwoTone,
  DesktopOutlined,
  EditOutlined,
  HomeOutlined, SmileOutlined,
  UserOutlined, FileOutlined, ToolOutlined, ApiOutlined, MoneyCollectOutlined,
} from '@ant-design/icons';
import { Button, Col, Layout, Menu, Row } from 'antd';
import { NavLink, useLocation } from "react-router-dom";
import { authentication } from "../auth/Authentication";
import Avatar from "antd/es/avatar/avatar";
import { healthCheck } from "../shared/CamlyApi";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem(<>Home<NavLink to='/' /></>, '/', <HomeOutlined />),
  getItem(<>CS 관리<NavLink to='/complain' /></>, '/complain', <DesktopOutlined />,
    [getItem(<>게시글<NavLink to='/complain/post' /></>, '/complain/post'), getItem(<>댓글<NavLink to='/complain/comment' /></>, '/complain/comment')]),
  getItem(<>공지사항 관리<NavLink to='/notice' /></>, '/notice', <FileOutlined />),
  getItem(<>이모티콘 관리<NavLink to='/emoji' /></>, '/emoji', <SmileOutlined />),
  getItem(<>계정 관리<NavLink to='/account' /></>, '/account', <UserOutlined />,
    [getItem(<>운영자 제재<NavLink to='/account/block' /></>, '/account/block'), getItem(<>이용자 숨기기<NavLink to='/account/hide' /></>, '/account/hide')]),
  getItem(<>서비스 점검 관리<NavLink to='/maintenance' /></>, '/maintenance', <ToolOutlined />),
  getItem(<>카테고리 관리<NavLink to='/category' /></>, '/category', <EditOutlined />),
  getItem(<>피처토글 관리<NavLink to='/feature-toggle' /></>, '/feature-toggle', <ApiOutlined />),
  getItem(<>프로모션 관리<NavLink to='/promotion' /></>, '/promotion', <MoneyCollectOutlined />),
  getItem(<>코드 관리<NavLink to='/code' /></>, '/code', <UserOutlined />,
    [getItem(<>도메인 관리<NavLink to='/code/domain' /></>, '/code/domain')]),
  getItem(<>알림 보내기<NavLink to='/notification' /></>, '/notification', <MoneyCollectOutlined />),
];

const BasicLayout = ({ children }) => {
  const phase = `${process.env.REACT_APP_PHASE}`;
  const [collapsed, setCollapsed] = useState(false);
  const [serverStatus, setServerStatus] = useState(false);
  const location = useLocation();

  useEffect(() => {
    healthCheck().then(({ data }) => {
      setServerStatus(data.status === 'UP');
    })
  }, [location])

  return <Layout style={{ minHeight: '100vh' }}>
    <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      <div className="logo" />
      <Menu theme="dark" defaultSelectedKeys={['/']} mode="inline" items={items} selectedKeys={location.pathname} />
    </Sider>
    <Layout className="site-layout">
      <Header>
        <Row>
          <Col span={12} style={{ textAlign: 'left' }}>
            <a href='/' target="_self" rel="noreferrer noopener" style={{ color: 'white' }}>
              <h2>Camly Admin Tool {phase === 'dev' && 'DEV'}{serverStatus ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{ marginLeft: '10px' }} /> : <CloseCircleTwoTone twoToneColor="#e3484c" style={{ marginLeft: '10px' }} />}</h2>
            </a>
          </Col>
          <Col span={12} style={{ textAlign: 'right', color: 'white' }}>
            <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} /> {authentication.user?.name}
            <Button danger type="text" size='small' onClick={() => {
              authentication.logout();
              window.location.reload();
            }}>logout</Button>
          </Col>
        </Row>
      </Header>
      <Content
        className="site-layout-background"
        style={{
          backgroundColor: 'white',
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
        }}
      >
        {children}
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        Copyright © 605Labs Corp. All rights reserved.
      </Footer>
    </Layout>
  </Layout>
}

export default BasicLayout;
