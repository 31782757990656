import {action, computed, observable, runInAction} from 'mobx';
import {STORE_STATE} from "../../constants/Values";
import {createMaintenance, deleteMaintenance, getMaintenance} from "../../shared/CamlyApi";

const NOTICE_CATEGORY_ID = 4;

const MaintenanceStore = () => observable.object({
  state: STORE_STATE.DEFAULT,
  maintenance: {},

  get isLoading () {
    return this.state === STORE_STATE.LOADING;
  },

  async fetchMaintenance (pagination) {
    this.state = STORE_STATE.LOADING;
    const {data} = await getMaintenance(pagination);
    runInAction(() => {
      this.maintenance.data = data?.datas;
      this.maintenance.totalCount = data.paging?.totalCount;
    })
    this.state = STORE_STATE.DONE;
  },

  async createMaintenance (values) {
    try {
      await createMaintenance(values);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async deleteMaintenance (id) {
    try {
      await deleteMaintenance(id);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }


}, {
  isLoading: computed,
  fetchMaintenance: action.bound,
  createMaintenance: action.bound,
  deleteMaintenance: action.bound,
});

export default MaintenanceStore;
