import React, {useEffect, useState} from 'react';
import {Divider, Form, DatePicker, Button, theme, Row, Col, Space, Table, Badge} from "antd";
import dayjs from "dayjs";
import ReportStore from "./ReportStore";
import {observer} from "mobx-react-lite";
import moment from "moment/moment";

const { Column, ColumnGroup } = Table;

const Home = () => {
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const [reportStore] = useState(ReportStore())
  const [unit, setUnit] = useState("day");
  const [startDate, setStartDate] = useState(dayjs().day(-7));
  const [endDate, setEndDate] = useState(dayjs().add(1, "d"));

  useEffect(() => {
    reportStore.fetchReports(unit, startDate, endDate);
  }, [startDate, endDate]);

  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };

  const onFinish = (values) => {
    setStartDate(values.dateRange[0]);
    setEndDate(values.dateRange[1]);
  };

  return <>
    <h2>운영지표</h2>
    <Divider />
    <Form
      form={form}
      initialValues={{dateRange: [startDate, endDate]}}
      name="search"
      style={formStyle}
      onFinish={onFinish}>
      <Row gutter={24}>
        <Col span={16}>
          <Form.Item name="dateRange" label="조회기간">
            <DatePicker.RangePicker />
          </Form.Item>
        </Col>
      </Row>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Space size="small">
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
        </Space>
      </div>
    </Form>
    <br/>
    <Table
        rowKey={data => data.date}
        dataSource={reportStore.mergedData}
        loading={reportStore.isLoading}
        pagination={false}
    >
      <Column title="날짜" dataIndex="date" key="date" width={150} render={date => {
        return moment().format("YYYY-MM-DD") === date ? <Badge color="red" text={date} /> : <>{date}</>
      }}/>
      <ColumnGroup title="계정">
        <Column title="신규" dataIndex="accountCount" key="accountCount" />
        <Column title="누적" dataIndex="accountCumulativeCount" key="accountCumulativeCount" />
      </ColumnGroup>
      <ColumnGroup title="게시글">
        <Column title="신규" dataIndex="postCount" key="postCount" />
        <Column title="누적" dataIndex="postCumulativeCount" key="postCumulativeCount" />
      </ColumnGroup>
      <ColumnGroup title="댓글">
        <Column title="신규" dataIndex="commentCount" key="commentCount" />
        <Column title="누적" dataIndex="commentCumulativeCount" key="commentCumulativeCount" />
      </ColumnGroup>
    </Table>
    <Divider />
    <Table
        title={() => <>오늘의 순수 접속자 명단 (Dummy계정 제외) / 총 {reportStore.dauData.length}명</>}
        rowKey={data => data.name}
        dataSource={reportStore.dauData}
        loading={reportStore.isLoading}
        pagination={false}
    >
      <Column title="닉네임" dataIndex="name" key="name"/>
      <Column title="대학교" dataIndex="emailDomain" key="emailDomain"/>
      <Column title="계정 생성일" dataIndex="createdDate" key="name"
              render={date => {
                return moment(date).format("YYYY-MM-DD HH:mm:ss");
              }}
              sorter={(a, b) => moment(a.createdDate) - moment(b.createdDate)}
      />
    </Table>
    <Divider />
    <Table
        title={() => <>지난 3일간 신규 가입자 활동</>}
        rowKey={data => data.name}
        dataSource={reportStore.retainData}
        loading={reportStore.isLoading}
        pagination={false}
    >
      <Column title="계정 생성일" dataIndex="createdDate" key="name"
              render={date => {
                return moment(date).format("YYYY-MM-DD HH:mm:ss");
              }}
              sorter={(a, b) => moment(a.createdDate) - moment(b.createdDate)}
      />
      <Column title="닉네임" dataIndex="name" key="name"/>
      <Column title="대학교" dataIndex="emailDomain" key="emailDomain"/>
      <Column title="작성 게시글" dataIndex="postCount" key="postCount"/>
      <Column title="작성 댓글" dataIndex="commentCount" key="commentCount"/>
      <Column title="이모지 반응" dataIndex="emojiCount" key="emojiCount"/>
    </Table>
  </>;
};

export default observer(Home);