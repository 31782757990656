import React, { useState } from 'react';
import { observer } from "mobx-react-lite";
import { Modal, Form, Input, Button, message, Upload } from "antd";
import { CODE_TYPE } from "../../../../constants/Values";
import S3Uploader from "../../../../shared/S3Uploader";

const CreateDomainModal = ({ modalOpen, setModalOpen, register, reload }) => {
  const [form] = Form.useForm();
  const [modalLoading, setModalLoading] = useState(false);
  const [univImageUrl, setUnivImageUrl] = useState(null);

  const createDomain = async values => {
    setModalLoading(true);
    const result = await register(CODE_TYPE.DOMAIN, values.code, {
      message: values.message,
      imageUrl: univImageUrl,
      redirectCode: values.redirectCode === "" ? null : values.redirectCode
    });
    setModalLoading(false);
    if (result) {
      setModalOpen(false);
      reload();
      message.info({ content: '도메인 등록에 성공했습니다.', key: 104, duration: 4 });
    } else {
      message.error({ content: '도메인 등록에 실패했습니다.', key: 104, duration: 4 });
    }
  }

  return <Modal
    open={modalOpen}
    title="신규 도메인 등록하기"
    onCancel={() => setModalOpen(false)}
    footer={[
      <Button key="back" onClick={() => setModalOpen(false)}>
        취소
      </Button>,
      <Button key="ok" loading={modalLoading} onClick={form.submit}>
        저장
      </Button>,
    ]}
  >
    <div>
      <Form form={form} name="register" onFinish={createDomain}>
        <Form.Item label="Code" name="code">
          <Input placeholder="등록할 대학교의 도메인" />
        </Form.Item>
        <Form.Item label="Display Name" name="message">
          <Input placeholder="클라이언트에 노출할 이름" />
        </Form.Item>
        <Form.Item label="Redirect Code" name="redirectCode">
          <Input placeholder="Redirect Code" />
        </Form.Item>
      </Form>
    </div>
    <S3Uploader
      univImageUrl={univImageUrl}
      onUploadReady={imageUrl => {
        setUnivImageUrl(imageUrl);
      }}
    />
  </Modal>;
};

export default observer(CreateDomainModal);
