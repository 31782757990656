import {action, computed, observable, runInAction} from 'mobx';
import {STORE_STATE} from "../../../constants/Values";
import {deleteHideAccount, getHideAccount} from "../../../shared/CamlyApi";

const AccountHideStore = () => observable.object({
  state: STORE_STATE.DEFAULT,
  hideAccount: [],

  get isLoading () {
    return this.state === STORE_STATE.LOADING;
  },

  async fetchHideAccounts (accountId) {
    this.state = STORE_STATE.LOADING;
    const {data} = await getHideAccount(accountId);
    runInAction(() => {
      this.hideAccount = data?.accountHides;
    })
    this.state = STORE_STATE.DONE;
  },

  async deleteHideMapping(accountId, hiddenAccountId) {
    try {
      await deleteHideAccount(accountId, hiddenAccountId);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

}, {
  isLoading: computed,
  fetchHideAccounts: action.bound,
  deleteHideMapping: action.bound,
});

export default AccountHideStore;
