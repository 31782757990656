import React from 'react';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {getUnivPreSignedImageUrl} from "./CamlyApi";
import _ from "lodash";

function S3Uploader({univImageUrl, onUploadReady}) {
  const imageUrlHost = process.env.REACT_APP_S3_BASE_URL;

  async function handleDrop([pendingImage], onProgress, onSuccess, onError) {
    const {data: preSignedUploadUrl} = await getUnivPreSignedImageUrl();

    // Upload the image to our pre-signed URL.
    const response = await fetch(
      new Request(preSignedUploadUrl.url, {
        method: 'PUT',
        body: pendingImage,
        headers: new Headers({
          'Content-Type': 'image/jpeg',
        }),
      }),
    );

    if (response.status !== 200) {
      // The upload failed, so let's notify the caller.
      onError();
      return;
    }
    onUploadReady && onUploadReady(imageUrlHost + preSignedUploadUrl.imageUrl);
    onSuccess();
  }

  return (
    <Upload
      forceRender
      fileList={univImageUrl ? [{
        uid: '1',
        name: _.last(univImageUrl.split('/')),
        status: 'done',
        url: univImageUrl,
      }] : []}
      multiple={false}
      customRequest={({ file, onProgress, onSuccess, onError }) => handleDrop([file], onProgress, onSuccess, onError)}>
      <Button icon={<UploadOutlined />}>Upload</Button>
    </Upload>
  );
}

export default S3Uploader;
