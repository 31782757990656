export const STORE_STATE = {
  DEFAULT: '기본',
  DONE: '완료',
  LOADING: '로딩중',
  ERROR: '에러',
};

export const CONTENTS_TYPE = {
  POST: 'POST',
  COMMENT: 'COMMENT',
}

export const CODE_TYPE = {
  FORBIDDEN_WORD: 'FORBIDDEN_WORD',
  DOMAIN: 'DOMAIN',
}

export const ACCOUNT_BLOCK_TYPE = {
  READ_WRITE_BLOCK: {
    name: 'READ_WRITE_BLOCK',
    desc: '읽기/쓰기 제한',
  },
  WRITE_BLOCK: {
    name: 'WRITE_BLOCK',
    desc: '쓰기 제한',
  },
}
