import axios from "axios";
import {message, Modal} from 'antd';
import {authentication} from '../auth/Authentication';

const UNAUTHORIZED_STATUS_CODE = 401;

const axiosConfig = () => {
  const setBaseUrl = baseUrl => {
    axios.defaults.baseURL = baseUrl;
    axios.defaults.withCredentials = false;
  };
  let requestInterceptorNumber = -1;
  let responseInterceptorNumber = -1;

  const setRequestInterceptor = onFulfilled => {
    if (requestInterceptorNumber > -1) {
      axios.interceptors.request.eject(requestInterceptorNumber);
    }
    requestInterceptorNumber = axios.interceptors.request.use(onFulfilled);
  };

  const setResponseInterceptor = (onFulfilled, onRejected) => {
    if (responseInterceptorNumber > -1) {
      axios.interceptors.response.eject(responseInterceptorNumber);
    }
    responseInterceptorNumber = axios.interceptors.response.use(onFulfilled, onRejected);
  };

  return {
    setDefaultAxiosSetting: () => {
      // eslint-disable-next-line no-undef
      const url = `${process.env.REACT_APP_API_BASE_URL}`;
      setBaseUrl(url);

      const defaultHeaders = {
        Authorization: `Bearer ${authentication.token}`,
        bsaServiceType: 'inhouseService',
      };

      const requestOnFulfilled = config => {
        config.headers = {...config.headers, ...defaultHeaders};
        return config;
      };

      setRequestInterceptor(requestOnFulfilled);

      const responseOnFulfilled = res => {
        return res;
      };

      const responseOnRejected = err => {
        if (err.response && err.response.status === UNAUTHORIZED_STATUS_CODE) {
          Modal.confirm({
            title: 'Unauthorized',
            content: '장시간 사용하지 않았거나, 인증에 문제가 발생하여 로그인이 필요합니다. 확인 버튼을 누르면 로그인 창으로 이동합니다.',
            onOk () {
              authentication.logout();
              window.location.reload();
            },
          });
        } else {
          message.error(err.response.data.message);
        }
        return Promise.reject(err);
      };

      setResponseInterceptor(responseOnFulfilled, responseOnRejected);
    },
    setBaseUrl,
    setRequestInterceptor,
    setResponseInterceptor,
  };
};

export default axiosConfig();
