import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Button, Col, Form, Input, Modal, Row, Select, Space, Switch, Table, Tooltip, message} from "antd";
import CategoryStore from "./CategoryStore";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const CATEGORY_TYPE = [{
  label: "교내",
  value: "EMAIL",
}, {
  label: "교외",
  value: "GENERAL",
}, {
  label: "공지사항",
  value: "NOTICE",
}, {
  label: "타임라인",
  value: "TIMELINE",
}, {
  label: "인기글",
  value: "HOT",
}];

const POST_TYPE = [{
  label: "기본",
  value: "BASIC",
}, {
  label: "팟구함",
  value: "LOOKING_FOR_PARTY",
}, {
  label: "RTP",
  value: "RTP",
}];

const columns = (showModal, updateCategoryUsable) => [
  {
    title: '카테고리 타입',
    key: 'id',
    dataIndex: 'type',
    render: type => _.find(CATEGORY_TYPE, {value: type}).label
  },
  {
    title: '카테고리 그룹',
    render: data => {
      if (data.groupKey === data.name) {
        return <Space align='center'>{data.groupKey}<Tooltip title="message properties에 다국어 추가가 필요합니다."><ExclamationCircleOutlined style={{color: '#e8ba41'}}/></Tooltip></Space>
      } else {
        return <>{data.groupKey} ({data.name})</>
      }
    }
  },
  {
    title: '게시글 타입',
    render: data => data.supportPostTypes
        ? data.supportPostTypes.map(type => <p key={type} style={{marginBottom: 0}}>{_.find(POST_TYPE, {value: type}).label}</p>)
        : '-',
  },
  {
    title: '이용가능 대학',
    dataIndex: 'accessibleDomains',
    render: domains => domains && domains.length > 0 ? domains.map(domain => <p key={domain} style={{marginBottom: 0}}>{domain}</p>) : 'ALL',
  },
  {
    title: '대학 게시판 잠김여부',
    render: data => data.type !== 'EMAIL' ? '-' : data.lock ? 'true' : 'false',
  },
  {
    title: '순서',
    dataIndex: 'order',
  },
  {
    title: '기능',
    render: data => <Space>
      <Button onClick={() => showModal(data, false)}>수정</Button>
      <Button onClick={() => {
        const clone = _.cloneDeep(data);
        delete clone.id;
        delete clone.categoryKey;
        showModal(clone, true)
      }}>추가</Button>
    </Space>,
  },
  {
    title: '운영여부',
    render: data => <Switch checkedChildren="ON" unCheckedChildren="OFF" checked={data.usable} onChange={value => updateCategoryUsable(data.id, value)}/>,
  },
];

const Category = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [store] = useState(CategoryStore());
  const [openModal, setOpenModal] = useState(false);
  const [isSubCategory, setIsSubCategory] = useState(false);

  useEffect(() => {
    store.fetchCategory();
  }, []);

  const showModal = (value, isSub) => {
    form.setFieldsValue(value)
    setIsSubCategory(isSub);
    setOpenModal(true);
  };

  const closeModal = () => {
    form.resetFields();
    setOpenModal(false);
  };

  const upsertCategory = values => {
    if (values.accessibleDomains && !Array.isArray(values.accessibleDomains)) {
      values.accessibleDomains = values.accessibleDomains.split(',').map(item => item.trim());
    }

    if (isSubCategory && values.groupKey === values.categoryKey) {
      messageApi.open({
        type: 'error',
        content: '카테고리는 그룹카테고리와 같은 key를 가질 수 없습니다.',
      })
      return;
    }

    if (values.id){
      store.updateCategory(values).then(closeModal);
    } else {
      store.createCategory(values).then(closeModal);
    }
  }

  const updateCategoryUsable = (id, isUsable) => {
    store.updateCategoryUsable(id, isUsable);
  }

  return (<>
    {contextHolder}
    <Row>
      <Col span={24}>
        <h2>카테고리 관리</h2>
        <p>카테고리 생성/조회/수정/삭제를 위한 페이지</p>
      </Col>
    </Row>
    <div style={{height: '40px', textAlign: 'right'}}>
      <Button onClick={() => store.fetchUnlockedCategory()} style={{marginRight: 10}}>제한 해제된 대학</Button>
      <Button onClick={() => showModal({})}>카테고리 그룹 생성</Button>
    </div>
    <div className="card-container">
      <Table
        rowKey={data => data.id}
        columns={columns(showModal, updateCategoryUsable)}
        dataSource={store.categories}
        loading={store.isLoading}
        expandable={{
          expandedRowRender: record => {
            const expandedColumns = [
              {
                title: '서브 카테고리',
                dataIndex: 'name',
              },
              {
                title: '게시글 타입',
                dataIndex: 'supportPostTypes',
                render: types => types.map(type => <p key={type} style={{marginBottom: 0}}>{_.find(POST_TYPE, {value: type}).label}</p>),
              },
              {
                title: '이용가능 대학',
                dataIndex: 'accessibleDomains',
                render: domains => domains && domains.length > 0 ? domains.map(domain => <p key={domain} style={{marginBottom: 0}}>{domain}</p>) : <>ALL</>,
              },
              {
                title: '기능',
                render: data => <><Button onClick={() => showModal(data, true)}>수정</Button></>,
              },
              {
                title: '운영여부',
                render: data => <Switch checkedChildren="ON" unCheckedChildren="OFF" checked={data.usable} onChange={value => updateCategoryUsable(data.id, value)}/>,
              },
            ]
            return <Table rowKey={r => r.id} columns={expandedColumns} dataSource={record?.subCategories} pagination={false} />
          },
          rowExpandable: record => record?.subCategories
        }}
        pagination={false}
      />
    </div>
    <Modal
      open={openModal}
      title="카테고리 관리"
      onOk={closeModal}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          취소
        </Button>,
        <Button key="submit" type="primary" loading={store.isLoading} onClick={form.submit}>
          저장
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={upsertCategory}
      >
        <Form.Item name="id" hidden={true} />
        <Form.Item
          name="type"
          label="카테고리 타입"
        >
          <Select
            style={{ width: '100%' }}
            placeholder="카테고리 타입 선택"
            disabled={form.getFieldValue("type")}
            options={CATEGORY_TYPE}
          />
        </Form.Item>
        <Form.Item
          name="groupKey"
          label="카테고리 그룹 key"
          rules={[
            {
              required: true,
              message: '그룹 key는 필수값 입니다.',
            },
          ]}
        >
          <Input disabled={isSubCategory} />
        </Form.Item>
        <Form.Item
          name="categoryKey"
          label="카테고리 key"
          rules={[
            {
              required: true,
              message: '카테고리 key는 필수값 입니다.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="supportPostTypes"
          label="지원하는 게시글 타입"
          rules={[
            {
              required: true,
              message: '최소 1개 이상의 게시글 타입을 선택하세요.',
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="게시글 타입 선택"
            options={POST_TYPE}
          />
        </Form.Item>
        <Form.Item
          name="accessibleDomains"
          label="이용가능 대학"
        >
          <Input />
        </Form.Item>
        <Form.Item
            name="order"
            label="정렬순서"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  </>);
};

export default observer(Category);
