import React, {useEffect, useState} from 'react';
import ComplaintStore from "../ComplaintStore";
import {observer} from "mobx-react-lite";
import {Table, Col, Row, Pagination} from "antd";
import {CONTENTS_TYPE} from "../../../constants/Values";
import _ from "lodash";
import ComplaintModal from "../modal/ComplainModal";
import {postColumns} from "../TableColumns";
import ContentsDetailModal from "../modal/ContentsDetailModal";

const Complaint = () => {
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [complaintStore] = useState(ComplaintStore());
  const [postModalOpen, setPostModalOpen] = useState(false);
  const [currentPostId, setCurrentPostId] = useState();
  const [complaintModalOpen, setComplaintModalOpen] = useState(false);
  const [currentComplaints, setCurrentComplaints] = useState();

  useEffect(() => {
    complaintStore.fetchComplaint(CONTENTS_TYPE.POST, pagination);
  }, [pagination]);

  const handlePostBlock = postId => {
    complaintStore.blockingPostByComplaint(postId).then(() => {
      complaintStore.fetchComplaint(CONTENTS_TYPE.POST, pagination);
    });
  }

  const handleComplete = id => {
    complaintStore.complete(id).then(() => {
      complaintStore.fetchComplaint(CONTENTS_TYPE.POST, pagination);
    });
  }

  const showPostModal = postId => {
    setCurrentPostId(postId);
    setPostModalOpen(true);
  };

  const showComplaintModal = id => {
    setCurrentComplaints(_.find(complaintStore.complaints.data, {id}));
    setComplaintModalOpen(true);
  }

  return (<>
    <Row>
      <Col span={24}>
        <h2>신고 관리</h2>
        <p>신고된 게시글에 대한 제재 및 모니터링을 위한 페이지</p>
      </Col>
    </Row>
    <div style={{height: '40px'}} />
    <div className="card-container">
      <Table
        rowKey={data => data.id}
        columns={postColumns(showPostModal, showComplaintModal, handlePostBlock, handleComplete)}
        dataSource={complaintStore.complaints.data}
        loading={complaintStore.isLoading}
        pagination={false}
      />
    </div>
    <div style={{marginTop: 10, textAlign: 'right'}}>
      <Pagination
        showSizeChanger
        pageSizeOptions={[10,20,30]}
        defaultPageSize={pagination.size}
        total={complaintStore.complaints?.totalCount}
        current={pagination.page + 1}
        onChange={(page, size) => {setPagination({page: page - 1, size})}}
      />
    </div>
    <ContentsDetailModal contentId={currentPostId} type={CONTENTS_TYPE.POST} modalOpen={postModalOpen} setModalOpen={setPostModalOpen}/>
    <ComplaintModal complain={currentComplaints} modalOpen={complaintModalOpen} setModalOpen={setComplaintModalOpen}/>
  </>);
};

export default observer(Complaint);
